.navTitle {
  color:#f9fcfc;
}

.jobTitle {
  font-size: 2em;
  font-weight: bold;
}

.name {
  font-size: 1.6em;
}